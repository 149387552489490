import axios from "axios";

export const axios1 = axios.create({
  baseURL: "https://77a320560599447a85e6d346bcc07348.api.mockbin.io/",
  headers: {
    "Content-Type": "application/json",
  },
});
export const axios2 = axios.create({
  baseURL: "https://8aae23c9734e4549a2c7a0725b5318cf.api.mockbin.io",
  headers: {
    "Content-Type": "application/json",
  },
});
