import * as React from "react";
import Lightbox from "yet-another-react-lightbox";
import { Download } from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";

import Images from "./images";

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = React.useState(-1);

  const Slides = [
    { src: "/image/map-1.jpg" },
    { src: "/image/map-2.jpg" },
    { src: "/image/map-3.jpg" },
    { src: "/image/map-4.jpg" },
  ];

  return (
    <div className="flex flex-col items-center w-full md:h-[80vh] gap-6 justify-center pb-10">
      <div className="text-slate-700 text-4xl font-semibold">
        Property Gallery
      </div>
      <Images data={Slides} onClick={(index) => setCurrentIndex(index)} />

      <Lightbox
        index={currentIndex}
        plugins={[Download]}
        close={() => setCurrentIndex(-1)}
        open={currentIndex >= 0}
        slides={Slides}
      />
    </div>
  );
};
export default Gallery;
