import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Navbar from "./components/navbar";
import LandingPage from "./pages/landing-page";
import PlotAvailability from "./pages/plot-availability";
import About from "./pages/about";

function App() {
  return (
    <div className="">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/availability" element={<PlotAvailability />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
