import React from "react";

const Images = ({ data, onClick }) => {
  const handleClick = (index) => {
    onClick(index);
  };

  return (
    <div className="grid  grid-cols-2 lg:grid-cols-4 w-full md:px-20 px-4 gap-4">
      {data.map((image, index) => (
        <img
          key={index}
          src={image.src}
          alt="map"
          className="hover:scale-105 transition-all ease-in-out cursor-pointer hover:shadow-lg"
          onClick={() => handleClick(index)}
        />
      ))}
    </div>
  );
};

export default Images;
