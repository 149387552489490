import React from "react";

const MapEmbed = () => {
  return (
    <div style={{ width: "100%", height: "400px" }} className="my-40">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14029.757619924712!2d77.0381087!3d28.4272425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe383c47429fb1488!2sALD%20Developers!5e0!3m2!1sen!2sin!4v1598679355412!5m2!1sen!2sin"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
      ></iframe>
    </div>
  );
};

export default MapEmbed;
