import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FiMenu, FiX } from "react-icons/fi";

const Navbar = () => {
  const [isSideNavOpen, setIsSideNavOpen] = useState(false);

  const toggleSideNav = () => {
    setIsSideNavOpen(!isSideNavOpen);
  };

  return (
    <nav className="bg-gray-900 py-6 px-4 md:px-20 h-[5rem] flex justify-between items-center sticky top-0 z-50 font-inter">
      <div className="flex items-center justify-center flex-col">
        <img
          className="w-10 md:w-16 rounded-lg"
          alt="icon"
          src={"/image/logo.jpg"}
        />
      </div>
      <button
        className="block md:hidden z-50 text-white"
        onClick={toggleSideNav}
      >
        {isSideNavOpen ? <FiX size={24} /> : <FiMenu size={24} />}
      </button>
      <ul
        className={`fixed md:static top-0 right-0 h-full md:h-auto bg-gray-900 md:bg-transparent flex flex-col md:flex-row items-center md:space-x-8 p-8 md:p-0 transition-transform transform md:transform-none ${
          isSideNavOpen ? "translate-x-0" : "translate-x-full"
        } md:translate-x-0`}
      >
        <li className="my-2 md:my-0">
          <NavLink
            exact
            to="/"
            className="text-gray-400 hover:text-white text-lg"
            activeClassName="text-white"
            onClick={toggleSideNav}
          >
            Home
          </NavLink>
        </li>
        <li className="my-2 md:my-0">
          <NavLink
            exact
            to="/about"
            className="text-gray-400 hover:text-white text-lg"
            activeClassName="text-white"
            onClick={toggleSideNav}
          >
            About
          </NavLink>
        </li>
        <li className="my-2 md:my-0">
          <NavLink
            to="/availability"
            className="text-gray-400 hover:text-white text-lg"
            activeClassName="text-white"
            onClick={toggleSideNav}
          >
            Plot Availability
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
