import React from "react";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";

import { PiProjectorScreenChartFill } from "react-icons/pi";
import { FaPeopleGroup } from "react-icons/fa6";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import {
  Carousel,
  AboutUs,
  Info,
  Testimonial,
  Contact,
  Gallery,
  MapEmbed,
  Footer,
} from "../components";

const LandingPage = () => {
  return (
    <div className="h-full w-full">
      <Carousel />
      <div id="about">
        <AboutUs />
      </div>
      <ParallaxProvider>
        <div className="relative h-screen overflow-hidden flex flex-col justify-center items-center text-white text-center px-4 md:px-10 lg:px-20 my-20 ">
          <Parallax
            className="absolute top-0 left-0 w-full h-full"
            y={[-20, 20]}
          >
            <div className="relative w-full h-full">
              <img
                src="/image/property-6.jpg"
                alt="Background"
                className="w-full h-full object-cover object-center aspect-video"
              />
              <div className="absolute inset-0 bg-black bg-opacity-40"></div>
            </div>
          </Parallax>
          <div className="relative flex flex-wrap justify-center gap-5 md:gap-10 lg:gap-20">
            <div className="flex flex-col items-center mb-4 md:mb-0">
              <PiProjectorScreenChartFill className="mb-2 w-8 h-8 md:w-12 md:h-12 lg:w-16 lg:h-16" />
              <p className="text-lg md:text-xl lg:text-2xl">30</p>
              <p className="text-sm md:text-base lg:text-lg">PROJECTS</p>
            </div>
            <div className="flex flex-col items-center mb-4 md:mb-0">
              <HiOutlineBuildingOffice2 className="mb-2 w-8 h-8 md:w-12 md:h-12 lg:w-16 lg:h-16" />
              <p className="text-lg md:text-xl lg:text-2xl">1</p>
              <p className="text-sm md:text-base lg:text-lg">HEAD OFFICE</p>
            </div>
            <div className="flex flex-col items-center mb-4 md:mb-0">
              <HiOutlineOfficeBuilding className="mb-2 w-8 h-8 md:w-12 md:h-12 lg:w-16 lg:h-16" />
              <p className="text-lg md:text-xl lg:text-2xl">8</p>
              <p className="text-sm md:text-base lg:text-lg">BRANCH OFFICES</p>
            </div>
            <div className="flex flex-col items-center mb-4 md:mb-0">
              <FaPeopleGroup className="mb-2 w-8 h-8 md:w-12 md:h-12 lg:w-16 lg:h-16" />
              <p className="text-lg md:text-xl lg:text-2xl">10000+</p>
              <p className="text-sm md:text-base lg:text-lg">
                SATISFIED CLIENTS
              </p>
            </div>
          </div>
        </div>
      </ParallaxProvider>
      <Gallery />
      <Info />
      <Testimonial />
      <MapEmbed />
      <Contact />
      <Footer />
    </div>
  );
};

export default LandingPage;
