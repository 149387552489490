import React from "react";
import { StickyScrollContainer } from "../../components";

const info = () => {
  const sampleContent = [
    {
      title: "Our Vision",
      description:
        "At ALD Developers, we envision creating vibrant communities that resonate with modern lifestyles while preserving the essence of Indian heritage and culture. Our aim is to build spaces that not only fulfill the needs of today but also anticipate the demands of tomorrow.",
    },
    {
      title: "What Sets Us Apart",
      description: (
        <div className="">
          <ul className="flex flex-col gap-2">
            <li>
              <b> Quality Construction:</b> We are committed to delivering
              projects of the highest quality, adhering to stringent
              construction standards and utilizing cutting-edge technologies and
              materials.
            </li>
            <li>
              <b> Customer-Centric Approach:</b> Our customers are at the heart
              of everything we do. We strive to understand their needs and
              aspirations, ensuring that every project is tailored to exceed
              their expectations.
            </li>
            <li>
              <b> Innovation:</b> Embracing innovation is ingrained in our DNA.
              From design to execution, we constantly seek innovative solutions
              to enhance the value proposition for our customers
            </li>
            <li>
              <b>Transparency:</b> We believe in fostering trust through
              transparency. From project timelines to pricing, we maintain clear
              and open communication with our customers, keeping them informed
              at every stage of the journey.
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "Our Portfolio",
      description: (
        <div className="flex flex-col gap-2">
          ALD Developers offers a diverse portfolio of properties including:
          <ul className="flex flex-col gap-2">
            <li>
              <b> Residential Plots:</b> Thoughtfully planned residential plots
              in prime locations, offering the perfect canvas to build your
              dream home.
            </li>
            <li>
              <b> Apartments:</b> Stylish and contemporary apartments designed
              to provide comfort, convenience, and luxury living experience
            </li>
            <li>
              <b> Commercial Properties:</b> Strategically located commercial
              spaces ideal for businesses looking to establish their presence in
              the thriving Delhi NCR region.
            </li>
          </ul>
        </div>
      ),
    },
    {
      title: "Our Commitment",
      description:
        "At ALD Developers, we are driven by a passion for excellence and a commitment to creating enduring value for our customers, stakeholders, and the community at large. With a relentless focus on quality, integrity, and innovation, we are dedicated to shaping the future of real estate in India.",
    },
  ];

  return (
    <StickyScrollContainer
      content={sampleContent}
      contentClassName="additional-class-for-content"
    />
  );
};

export default info;
