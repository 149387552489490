import React from "react";

const Card = ({
  block,
  name,
  dimensions,
  charges,
  project,
  size,
  status,
  type,
  number,
}) => {
  return (
    <div className="shadow-md rounded-lg p-6 flex flex-col gap-2 hover:scale-105 transition-all ease-in-out">
      <div className="gap-2 flex ">
        <span className={"text-gray-500 font-[500]"}>Block:</span>
        <span>{block}</span>
      </div>
      <div className="gap-2 flex ">
        <span className={"text-gray-500 font-[500]"}>Customer Name:</span>
        <span>{name}</span>
      </div>
      <div className="gap-2 flex ">
        <span className={"text-gray-500 font-[500]"}>Dimensions:</span>
        <span>{dimensions}</span>
      </div>
      <div className="gap-2 flex ">
        <span className={"text-gray-500 font-[500]"}>PLC Charges:</span>
        <span>{charges}</span>
      </div>
      <div className="gap-2 flex ">
        <span className={"text-gray-500 font-[500]"}>Project:</span>
        <span>{project}</span>
      </div>
      <div className="gap-2 flex ">
        <span className={"text-gray-500 font-[500]"}>Size:</span>
        <span>{size}</span>
      </div>
      <div className="gap-2 flex ">
        <span className={"font-[500] text-gray-500"}>Status:</span>
        <span
          className={`${
            status === "Available"
              ? "text-blue-600"
              : status === "Token Received"
              ? "text-yellow-600"
              : "text-green-600"
          }`}
        >
          {status}
        </span>
      </div>
      <div className="gap-2 flex ">
        <span className={"text-gray-500 font-[500]"}>Type:</span>
        <span>{type}</span>
      </div>
      <div className="gap-2 flex ">
        <span className={"text-gray-500 font-[500]"}>Unit Number:</span>
        <span>{number}</span>
      </div>
    </div>
  );
};

export default Card;
