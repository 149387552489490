import React from "react";
import { Carousel } from "antd";

const App = () => (
  <div className="w-full h-[90vh] mb-20">
    <Carousel arrows infinite={true} autoplay>
      <div className="w-full h-[90vh] relative">
        <img
          src="/image/property-1.jpg"
          alt="1"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-40"></div>
      </div>
      <div className="w-full h-[90vh] relative">
        <img
          src="/image/property-2.jpg"
          alt="2"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-40"></div>
      </div>
      <div className="w-full h-[90vh] relative">
        <img
          src="/image/property-3.jpg"
          alt="3"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-40"></div>
      </div>
    </Carousel>
  </div>
);

export default App;
