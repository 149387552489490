import React from "react";

const about = () => {
  return (
    <div
      className="w-full h-full flex justify-center items-center flex-col px-4 gap-6 py-20"
      id="about"
    >
      <div className="text-4xl font-bold text-slate-600">About Us</div>
      <div className="md:w-3/4 flex flex-col md:flex-row border-4 items-center rounded-3xl gap-6 border-slate-400 p-6 bg-slate-200 shadow-lg shadow-slate-400 ">
        <div className="md:w-1/2 h-full lg:text-lg md:text-base text-sm">
          ALD Developers is a leading real estate developer operating in the
          dynamic market of Delhi NCR, India. With a profound commitment to
          excellence and innovation, we specialize in the development and
          management of residential plots, apartments, and commercial
          properties. <br />
          <br />
          Our journey began with a vision to redefine the real estate landscape,
          offering unparalleled quality, reliability, and customer satisfaction.
          Established on the principles of integrity, transparency, and
          customer-centricity, ALD Developers has emerged as a trusted name in
          the industry.
        </div>
        <div className="md:w-1/2 h-full">
          <img src="/image/property-5.jpg" alt="" className="rounded-2xl" />
        </div>
      </div>
    </div>
  );
};

export default about;
