import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-10 w-full flex flex-col items-center">
      <div className="flex flex-wrap justify-around">
        <div className="w-full lg:w-1/3 mb-8 lg:mb-0 px-4">
          <img
            src="/image/logo.jpg"
            alt="ALD Developers"
            className="h-12 mb-4"
          />
          <p className="text-sm">
            The company has been formed with the desire of perfection and
            dedication to fulfill the commitments, with the aspiration of
            customer satisfaction.
            <br />
            <br />
            Clear vision, Expertise, honesty and good corporate governance
            experience is behind the success of the company.
          </p>
          <Link to={"/about"} className="text-orange-500">
            Learn More »
          </Link>
        </div>
        <div className="w-full lg:w-1/4 mb-8 lg:mb-0 px-4">
          <h3 className="text-lg font-semibold mb-4">OUR COMPANY</h3>
          <ul>
            <li>
              <Link
                to="/about"
                className="text-sm text-gray-400 hover:text-white"
              >
                About
              </Link>
            </li>
            <li>
              <Link
                to="/availability"
                className="text-sm text-gray-400 hover:text-white"
              >
                Plot Availabilty
              </Link>
            </li>
          </ul>
        </div>

        <div className="w-full lg:w-1/3 px-4">
          <h3 className="text-lg font-semibold mb-4">CONTACT INFO</h3>
          <p className="text-sm">
            ALD Developers
            <br />
            1105, 1st Floor Sec-47, Badshahpur Sohna Rd Hwy
            <br />
            near Subhash Chowk,
            <br />
            Gurugram, Haryana 122001
            <br />
            Phone:+91 9990010105
            <br />
            <a
              href="mailto:anujsharma160293@gmail.com"
              className="text-orange-500"
            >
              anujsharma160293@gmail.com
            </a>
          </p>
          <div className="flex mt-4">
            <a href="#" className="text-gray-400 hover:text-white mr-4">
              <i className="fa fa-twitter"></i>
            </a>
            <a href="#" className="text-gray-400 hover:text-white mr-4">
              <i className="fa fa-instagram"></i>
            </a>
            <a href="#" className="text-gray-400 hover:text-white">
              <i className="fa fa-facebook"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="text-center text-gray-400 text-sm mt-8">
        © ALD Developers PVT. LTD. ALL RIGHTS RESERVED 2024
      </div>
    </footer>
  );
};

export default Footer;
