import React from "react";

function Contact() {
  return (
    <div className="flex flex-col items-center justify-center p-4 m-8 bg-green-200 bg-opacity-30 rounded-lg shadow-md md:m-2 md:p-4 lg:m-32">
      <div className="text-center">
        <h1 className="text-2xl font-bold">CONTACT US</h1>
      </div>
      <div className="w-full mt-4">
        <form className="flex flex-col items-center">
          <div className="flex flex-col items-center w-4/5 mb-4">
            <label htmlFor="name" className="mb-2">
              Your Name (Required)
            </label>
            <input
              type="text"
              id="name"
              className="w-full p-2 border rounded-md"
              required
            />
          </div>

          <div className="flex flex-col items-center w-4/5 mb-4">
            <label htmlFor="email" className="mb-2">
              Your Email (Required)
            </label>
            <input
              type="email"
              id="email"
              className="w-full p-2 border rounded-md"
              required
            />
          </div>

          <div className="flex flex-col items-center w-4/5 mb-4">
            <label htmlFor="subject" className="mb-2">
              Subject
            </label>
            <input
              type="text"
              id="subject"
              className="w-full p-2 border rounded-md"
            />
          </div>

          <div className="flex flex-col items-center w-4/5 mb-4">
            <label htmlFor="message" className="mb-2">
              Your Message
            </label>
            <textarea
              id="message"
              cols="30"
              rows="10"
              className="w-full p-2 border rounded-md"
            ></textarea>
          </div>

          <div className="flex flex-col items-center w-4/5 mb-4">
            <iframe
              title="reCAPTCHA"
              src="https://www.google.com/recaptcha/api2/anchor?ar=1&amp;k=6LefmHIaAAAAABYuhssU1xijSRVdU1Gz3tbhboUW&amp;co=aHR0cHM6Ly9iYW55YW5uYXRpb24uY29tOjQ0Mw..&amp;hl=en&amp;v=VZKEDW9wslPbEc9RmzMqaOAP&amp;size=normal&amp;cb=n7ltcpj3p7rp"
              width="304"
              height="78"
              role="presentation"
              name="a-jc0sodn3wgeh"
              frameBorder="0"
              scrolling="no"
              sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox"
              className="w-3/5"
            ></iframe>
          </div>

          <div className="flex flex-col items-center w-4/5">
            <button className="px-4 py-2 mt-4 text-lg transition-all border-2 border-black rounded-md hover:bg-lime-500 hover:border-lime-300">
              Send
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Contact;
