import React from "react";
import { Carousel } from "antd";

function Testimonial() {
  return (
    <>
      <div className="relative text-center bg-slate-700 pt-8 mt-24 md:mx-40 rounded-tr-3xl lg:mx-64 ">
        <h1 className="text-3xl font-bold text-slate-200">Testimonials</h1>
      </div>
      <div className="bg-slate-700 p-6 md:mx-40 lg:mx-64 rounded-bl-3xl py-10">
        <Carousel arrows infinite={true} autoplay>
          <div className="py-6 px-4  text-white ">
            <div className="text-center text-lg mb-4">
              <p>
                "Choosing ALD Developers for my real estate investment was the
                best decision I made. From the initial consultation to the final
                handover of my property, their professionalism and attention to
                detail were exceptional. The project was completed on time, and
                the quality of construction surpassed my expectations. I highly
                recommend ALD Developers to anyone looking for a reliable and
                trustworthy real estate partner in the Delhi NCR region."
              </p>
            </div>
            <div className="text-center text-xl mt-4">
              <h3>Mr. Rahul Sharma</h3>
            </div>
          </div>
          <div className="py-6 px-4 text-white ">
            <div className="text-center text-lg mb-4">
              <p>
                "ALD Developers made my dream of owning a home a reality. Their
                team guided me through every step of the process, ensuring that
                I found the perfect property within my budget. The transparency
                in their dealings and the integrity they displayed throughout
                the transaction gave me peace of mind. I am immensely satisfied
                with my purchase and grateful to ALD Developers for their
                commitment to excellence."
              </p>
            </div>
            <div className="text-center text-xl mt-4">
              <h3>Mrs. Priya Singh</h3>
            </div>
          </div>
          <div className="py-6 px-4 text-white ">
            <div className="text-center text-lg mb-4">
              <p>
                "ALD Developers exceeded my expectations in every aspect of my
                real estate transaction. Their dedication to customer
                satisfaction is unparalleled. Not only did they deliver a
                high-quality property, but they also provided exceptional
                after-sales service, addressing any concerns promptly and
                efficiently. I wholeheartedly endorse ALD Developers as the
                go-to choice for real estate solutions in the Delhi NCR region."
              </p>
            </div>
            <div className="text-center text-xl mt-4">
              <h3>Mr. Ankit Kapoor</h3>
            </div>
          </div>
        </Carousel>
      </div>
    </>
  );
}

export default Testimonial;
