import { axios1, axios2 } from "../utils/axios";

export const getProjects = async () => {
  try {
    const response = await axios2.get("/");
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getResults = async (type, project) => {
  try {
    const response = await axios1.get(`?type=${type}&project_id=${project}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
