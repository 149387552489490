import React, { useEffect } from "react";
import { Select } from "antd";

import { getProjects, getResults } from "../services/api";
import { Card } from "../components";

const Availability = () => {
  const [projects, setProjects] = React.useState([]);
  const [project, setProject] = React.useState("");
  const [type, setType] = React.useState("");
  const [block, setBlock] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [results, setResults] = React.useState([]);

  const handleChange = (value, type) => {
    if (type === "project") {
      setProject(value);
    } else if (type === "type") {
      setType(value);
    } else if (type === "block") {
      setBlock(value);
    }
  };

  useEffect(() => {
    const fetchProjects = async () => {
      const response = await getProjects();
      const formattedProjects = response.projects.map((project) => ({
        value: project.id,
        label: project.name,
      }));
      setProjects(formattedProjects);
    };

    fetchProjects();
  }, []);

  const handleSend = async () => {
    if (!project || !type || !block) {
      alert("Please select all fields");
      return;
    }
    setLoading(true);
    try {
      const response = await getResults(project, type);
      console.log(response);
      setResults(response);
      setLoading(false);
      setProject("");
      setType("");
      setBlock("");
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const types = [
    { value: "flat", label: "Flat" },
    { value: "plot", label: "Plot" },
    { value: "shop", label: "Shop" },
  ];
  const blocks = [
    { value: "A", label: "A" },
    { value: "A'", label: "A'" },
    { value: "B", label: "B" },
    { value: "B'", label: "B'" },
  ];

  return (
    <div className="py-20 flex w-full smooth-scroll overflow-y-auto">
      <div className="flex flex-col w-full items-center gap-10">
        <div className="text-3xl font-semibold ">Plot Availability</div>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-10 w-full md:w-2/3 px-4 md:px-0">
          <div className="flex flex-col gap-2">
            <span className="">Project Name</span>
            <Select
              style={{ width: "100%" }}
              placeholder="Select"
              onChange={(value) => handleChange(value, "project")}
              options={projects}
            />
          </div>
          <div className="flex flex-col gap-2">
            <span className="">Type</span>
            <Select
              style={{ width: "100%" }}
              placeholder="Select"
              onChange={(value) => handleChange(value, "type")}
              options={types}
            />
          </div>
          <div className="flex flex-col gap-2">
            <span className="">Block</span>
            <Select
              style={{ width: "100%" }}
              placeholder="Select"
              onChange={(value) => handleChange(value, "block")}
              options={blocks}
            />
          </div>
        </div>
        <div className="">
          <button
            onClick={() => handleSend()}
            className="bg-[#111827] flex gap-2 text-white px-8 py-2 rounded-lg hover:bg-blue-900 transition-all ease-in-out active:scale-95"
          >
            {loading && (
              <div className="h-5 w-5 border-t-2 rounded-full animate-spin"></div>
            )}
            Submit
          </button>
        </div>

        {results.length > 0 && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-full md:w-2/3 px-4 md:px-0 gap-10">
            {results.map((result, index) => (
              <Card
                key={index}
                name={result["Customer Name"]}
                block={result.Block}
                dimensions={result.Dimensions}
                charges={result["PLC Charges"]}
                project={result.Project}
                size={result.Size}
                status={result.Status}
                type={result.Type}
                number={result["Unit Number"]}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Availability;
